import React from 'react';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import emptyCart from './assets/empty.jpg';
import emptyCart2x from './assets/empty@2x.jpg';
import emptyCart3x from './assets/empty@3x.jpg';

// Styles
import styles from './Cart.styl';

const cx = classNames.bind(styles);

const Empty = () => (
  <div className={cx('Cart__empty')}>
    <div className={cx('Cart__empty-pic')}>
      <img src={emptyCart} srcSet={`${emptyCart2x} 2x, ${emptyCart3x} 3x`} alt="empty cart" />
    </div>
    <div>
      <FormattedMessage id="cart.empty" />
    </div>
  </div>
);

export default Empty;
