import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames/bind';
import _debounce from 'lodash/debounce';

// Components
import Cart from '../../../components/Cart';
import OrderHistoryWidget from '../../../components/OrderHistoryWidget';
import PromocodeWidget from '../../../components/PromocodeWidget';

// Style
import styles from './MainSidebar.styl';

const cx = classNames.bind(styles);

export default function MainSidebar() {
  const [isCartOpen, setIsCartOpen] = useState(true);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [top, setTop] = useState(80);

  const lastScrollPosition = useRef(0);
  const sidebarRef = useRef(null);

  const toggleCart = () => {
    setIsCartOpen(prev => !prev);
  };

  const toggleHistory = () => {
    setIsHistoryOpen(prev => !prev);
  };

  const handleScroll = e => {
    if (!sidebarRef.current) {
      return;
    }

    const windowScrollY = window.scrollY;
    const windowHeight = e.target.documentElement.clientHeight;
    const sidebarObject = sidebarRef.current.getBoundingClientRect();
    const scrollDirectionUp = windowScrollY < lastScrollPosition.current;

    const scrollStep = Math.abs(windowScrollY - lastScrollPosition.current);
    const heightDiff = windowHeight - sidebarObject.height;

    let value = 80;

    // начальный Y сайдбара 80px
    // если мы долистали до сайдбара
    if (sidebarObject.y <= 80) {
      // если высота сайдбара больше чем (высота экрана - высота хэдера)
      if (heightDiff <= 80) {
        // если скроллим вверх
        if (scrollDirectionUp) {
          // если мы выше начальной позиции сайдбара, то ставим 80
          value = sidebarObject.y + scrollStep >= 80 ? 80 : sidebarObject.y + scrollStep;
          // если скроллим вниз
        } else {
          // умножаем на 0.8, чтобы на 0.8-х экрана торчал сайдбар
          value =
            sidebarObject.y - scrollStep <= windowHeight * 0.8 - sidebarObject.height
              ? windowHeight * 0.8 - sidebarObject.height
              : sidebarObject.y - scrollStep;
        }
      }

      setTop(value);
    }

    lastScrollPosition.current = windowScrollY;
  };

  const handleScrollDebounced = useCallback(
    _debounce(e => handleScroll(e), 250),
    [],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDebounced, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollDebounced);
    };
  }, []);

  return (
    <aside className={cx('MainSidebar')} style={{ top: `${top}px` }} ref={sidebarRef}>
      <Cart className={cx('MainSidebar__cart')} isOpen={isCartOpen} toggle={toggleCart} />
      <OrderHistoryWidget
        className={cx('MainSidebar__history')}
        isOpen={isHistoryOpen}
        toggle={toggleHistory}
      />
      <PromocodeWidget className={cx('MainSidebar__promocode')} />
    </aside>
  );
}
